define("ember-cli-cloudinary-light/components/cloudinary-image", ["exports", "ember-cli-cloudinary-light/utils/variable-formatter"], function (_exports, _variableFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CloudinaryImageComponent = Ember.Component.extend({
    tagName: 'img',
    attributeBindings: ['src', 'width', 'height'],
    width: Ember.computed('options.{width,crop}', function () {
      if (Ember.get(this, 'options.crop') === 'limit' || Ember.get(this, 'options.crop') === 'fit' || Ember.get(this, 'options.crop') === 'lfill') {
        return null;
      }

      return Ember.get(this, 'options.width');
    }),
    height: Ember.computed('options.{height,crop}', function () {
      if (Ember.get(this, 'options.crop') === 'limit' || Ember.get(this, 'options.crop') === 'fit' || Ember.get(this, 'options.crop') === 'lfill') {
        return null;
      }

      return Ember.get(this, 'options.height');
    }),
    crop: Ember.computed.oneWay('options.crop'),
    fetch_format: Ember.computed.oneWay('options.fetch_format'),
    quality: Ember.computed.oneWay('options.quality'),
    default_image: Ember.computed.oneWay('options.default_image'),
    src: Ember.computed('publicId', 'width', 'height', 'crop', 'fetch_format', 'quality', 'default_image', function () {
      var cloudName = Ember.getOwner(this).resolveRegistration('config:environment').cloudinary.cloudName;
      var params = (0, _variableFormatter.default)(Ember.get(this, 'options'));
      var publicId = Ember.get(this, 'publicId');
      var image = "https://res.cloudinary.com/".concat(cloudName, "/image/upload").concat(params, "/").concat(publicId);
      return Ember.String.htmlSafe(image);
    })
  });
  CloudinaryImageComponent.reopenClass({
    positionalParams: ['publicId', 'options']
  });
  var _default = CloudinaryImageComponent;
  _exports.default = _default;
});