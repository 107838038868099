define("ember-cli-cloudinary-light/components/cloudinary-video", ["exports", "ember-cli-cloudinary-light/utils/variable-formatter"], function (_exports, _variableFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CloudinaryVideoComponent = Ember.Component.extend({
    tagName: 'source',
    attributeBindings: ['src', 'width', 'height'],
    width: Ember.computed.oneWay('options.width'),
    height: Ember.computed.oneWay('options.height'),
    crop: Ember.computed.oneWay('options.crop'),
    fetch_format: Ember.computed.oneWay('options.fetch_format'),
    quality: Ember.computed.oneWay('options.quality'),
    radius: Ember.computed.oneWay('options.radius'),
    src: Ember.computed('publicId', 'width', 'height', 'crop', 'fetch_format', 'quality', 'radius', function () {
      var cloudName = Ember.getOwner(this).resolveRegistration('config:environment').cloudinary.cloudName;
      var options = Ember.get(this, 'options');
      var params = (0, _variableFormatter.default)(options);
      var publicId = Ember.get(this, 'publicId');
      var cloudinaryVideoTag = "https://res.cloudinary.com/".concat(cloudName, "/video/upload").concat(params, "/").concat(publicId);
      return Ember.String.htmlSafe(cloudinaryVideoTag);
    })
  });
  CloudinaryVideoComponent.reopenClass({
    positionalParams: ['publicId', 'options']
  });
  var _default = CloudinaryVideoComponent;
  _exports.default = _default;
});