define("ember-simple-auth-auth0/utils/errors", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Auth0Error = Auth0Error;

  function Auth0Error(payload) {
    var message = 'Auth0 operation failed';

    if ((0, _typeof2.default)(payload) === 'object' && payload !== null) {
      if (payload.error_description) {
        payload.error_description = decodeURI(payload.error_description);
      }

      var errorCode = payload.error || 'unknown';
      var errorDesc = payload.error_description || message;
      message = "Auth0 returned error `".concat(errorCode, "`: ").concat(errorDesc);
      this.name = errorCode;
    } else if (typeof payload === 'string') {
      message += ": ".concat(payload);
      payload = {};
    }

    Ember.Error.call(this, message);
    this.payload = payload;
  }

  Auth0Error.prototype = Object.create(Ember.Error.prototype);
});