define("ember-cli-cloudinary-light/components/cloudinary-resource-list", ["exports", "ember-cli-cloudinary-light/templates/components/cloudinary-resource-list", "fetch"], function (_exports, _cloudinaryResourceList, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CloudinaryResourceList = Ember.Component.extend({
    layout: _cloudinaryResourceList.default,
    init: function init() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'cloudinaryTag')) {
        this.fetchCloudinaryResourceList().then(this.handleCloudinaryResponse.bind(this)).catch(function (error) {
          Ember.debug("Error fetching Cloudinary Resource List: ".concat(error));
        });
      }
    },
    buildUrl: function buildUrl() {
      var cloudName = Ember.getOwner(this).resolveRegistration('config:environment').cloudinary.cloudName;
      var tag = Ember.get(this, 'cloudinaryTag');
      return "https://res.cloudinary.com/".concat(cloudName, "/image/list/").concat(tag, ".json");
    },
    fetchCloudinaryResourceList: function fetchCloudinaryResourceList() {
      var url = this.buildUrl();
      return (0, _fetch.default)(url).then(function (response) {
        return response.json();
      });
    },
    handleCloudinaryResponse: function handleCloudinaryResponse(response) {
      response.resources.sort(function (a, b) {
        if (!a.context || !a.context.custom || !b.context || !b.context.custom) {
          return;
        }

        var orderA = a.context.custom.order;
        var orderB = b.context.custom.order;

        if (orderA < orderB) {
          return -1;
        }

        if (orderA > orderB) {
          return 1;
        }

        return 0;
      });
      Ember.set(this, 'items', response.resources);
      return response.resources;
    }
  });
  CloudinaryResourceList.reopenClass({
    positionalParams: ['cloudinaryTag']
  });
  var _default = CloudinaryResourceList;
  _exports.default = _default;
});