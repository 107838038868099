define('ember-algolia/services/algolia', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    search: function search(query, params, callback) {
      if (query) {
        if (Array.isArray(query) && typeof params === 'function') // if multiple indices
          return this.get('client').search(query, params);else if (typeof params === 'function') // if no params
          return this.accessIndex(query.indexName).search(query.query, params);else if ((typeof params === 'undefined' ? 'undefined' : _typeof(params)) === 'object' && typeof callback === 'function') // if params and callback
          return this.accessIndex(query.indexName).search(query.query, params, callback);
      }
      Ember.Logger.error('Could not search algolia for query "' + query + '"');
    },
    getById: function getById(indexName, ids, params, callback) {
      if (typeof indexName === 'string' && ids) {
        if (Array.isArray(ids)) {
          // if multiple objects
          if (typeof params === 'function') return this.accessIndex(indexName).getObjects(ids, params);else if (Array.isArray(params) && typeof callback === 'function') return this.accessIndex(indexName).getObjects(ids, params, callback);
        } else {
          // if one object
          if (typeof params === 'function') return this.accessIndex(indexName).getObject(ids, params);else if (Array.isArray(params) && typeof callback === 'function') return this.accessIndex(indexName).getObject(ids, params, callback);
        }
      }
      Ember.Logger.error('Could not search algolia for object "' + query + '"');
    },
    accessIndex: function accessIndex(IndexName) {
      if (!this.get('indices').get(IndexName)) this.get('indices').set(IndexName, this.get('client').initIndex(IndexName));
      return this.get('indices').get(IndexName);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('indices', new Ember.Object({}));
    }
  });
});