define("ember-cli-cloudinary-light/helpers/safe-cloudinary-url", ["exports", "ember-cli-cloudinary-light/utils/variable-formatter"], function (_exports, _variableFormatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      var cloudName = Ember.getOwner(this).resolveRegistration('config:environment').cloudinary.cloudName;
      var publicId = Ember.Handlebars.Utils.escapeExpression(params[0]);
      var parameters = (0, _variableFormatter.default)(hash);

      if (publicId) {
        return Ember.String.htmlSafe("background-image: url('https://res.cloudinary.com/".concat(cloudName, "/image/upload").concat(parameters, "/").concat(publicId, "')"));
      }
    }
  });

  _exports.default = _default;
});