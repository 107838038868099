define("ember-data-storefront/-private/utils/get-key", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shoeboxize = _exports.cacheKey = _exports.queryCacheKey = _exports.serializeObject = void 0;

  var _serializeParams = function _serializeParams() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var prefix = arguments.length > 1 ? arguments[1] : undefined;
    var query = Object.keys(params).sort().map(function (key) {
      var value = params[key];

      if (params.constructor === Array) {
        key = "".concat(prefix, "[]");
      } else if (params.constructor === Object) {
        key = prefix ? "".concat(prefix, "[").concat(key, "]") : key;
      }

      if ((0, _typeof2.default)(value) === 'object' && value !== null) {
        return _serializeParams(value, key);
      } else {
        return "".concat(key, "=").concat(encodeURIComponent(value));
      }
    });
    return [].concat.apply([], query).join('&');
  };

  var serializeObject = function serializeObject(params) {
    return _serializeParams(params);
  };

  _exports.serializeObject = serializeObject;

  var queryCacheKey = function queryCacheKey(query) {
    return cacheKey([query.type, query.id, query.params]);
  };

  _exports.queryCacheKey = queryCacheKey;

  var cacheKey = function cacheKey(args) {
    return args.map(function (part) {
      return (0, _typeof2.default)(part) === "object" ? serializeObject(part) : part;
    }).filter(function (part) {
      return !!part;
    }).join('::');
  };

  _exports.cacheKey = cacheKey;

  var shoeboxize = function shoeboxize(key) {
    return key.replace(/&/g, '--'); // IDGAF
  };

  _exports.shoeboxize = shoeboxize;
});