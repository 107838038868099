define("ember-cli-cloudinary-light/templates/components/cloudinary-resource-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jZCT47B1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"items\"],[[25,[\"items\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-cloudinary-light/templates/components/cloudinary-resource-list.hbs"
    }
  });

  _exports.default = _default;
});