define("ember-simple-auth-auth0/utils/get-session-expiration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getSessionExpiration;

  /**
   * Get the token expiration time from the specified session data object.
   * If an ID token is defined, use the `exp` field since it's nice and
   * well-defined. Otherwise, calculate an expiration time from the
   * expiresIn field and the time the session object was created.
   * 
   * @return {Expiration time of token (Unix timestamp)}
   */
  function getSessionExpiration(sessionData) {
    var idTokenExpiration = Ember.get(sessionData, 'idTokenPayload.exp');

    if (idTokenExpiration) {
      return idTokenExpiration;
    } else {
      var issuedAt = Ember.getWithDefault(sessionData, 'issuedAt', 0);
      var expiresIn = Ember.getWithDefault(sessionData, 'expiresIn', 0);
      return issuedAt + expiresIn;
    }
  }
});