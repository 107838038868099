define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h/ODntyX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[24,0,[]],[25,[\"flash\"]],[29,\"action\",[[24,0,[]],\"close\"],null]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[25,[\"flash\",\"message\"]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"showProgressBar\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"alert-progress\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"alert-progressBar\"],[12,\"style\",[23,\"progressDuration\"]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-flash/templates/components/flash-message.hbs"
    }
  });

  _exports.default = _default;
});