define('ember-data-date-only-transform/transforms/date-only', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var millisecondsInHour = 60000;

  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      var type = typeof serialized === 'undefined' ? 'undefined' : _typeof(serialized);

      if (type === 'string') {
        // Taken from http://stackoverflow.com/a/17346406/215086
        var tIndex = serialized.indexOf('T');
        var serializedDateSection = tIndex >= 0 ? serialized.slice(0, tIndex) : serialized;
        var date = new Date(serializedDateSection);
        var offset = date.getTimezoneOffset();
        return new Date(date.getTime() + offset * millisecondsInHour);
      } else if (type === 'number') {
        return new Date(serialized);
      } else if (serialized == null) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    },


    // Serialize's a date object into the YYYY-MM-DD format
    serialize: function serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        // can't use date.toISOString().split('T')[0] because it can return the previous day
        // for people in the Eastern hemisphere
        var month = ('0' + (date.getMonth() + 1)).substr(-2);
        var day = ('0' + date.getDate()).substr(-2);
        return date.getFullYear() + '-' + month + '-' + day;
      } else {
        return null;
      }
    }
  });
});