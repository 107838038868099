define("ember-simple-auth-auth0/services/auth0", ["exports", "auth0-js", "ember-simple-auth-auth0/utils/create-session-data-object", "ember-simple-auth-auth0/utils/errors"], function (_exports, _auth0Js, _createSessionDataObject, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    inTesting: Ember.computed(function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      return config.environment === 'test';
    }),

    /**
     * The env config found in the environment config.
     * ENV['ember-simple-auth'].auth0
     *
     * @type {Object}
     */
    config: Ember.computed({
      get: function get() {
        var emberSimpleAuthConfig = Ember.get(this, '_environmentConfig')['ember-simple-auth'];
        (false && !(emberSimpleAuthConfig) && Ember.assert('ember-simple-auth config must be defined', emberSimpleAuthConfig));
        (false && !(emberSimpleAuthConfig.auth0) && Ember.assert('ember-simple-auth.auth0 config must be defined', emberSimpleAuthConfig.auth0));
        return emberSimpleAuthConfig.auth0;
      }
    }),

    /**
     * The Auth0 App ClientID found in your Auth0 dashboard
     * @type {String}
     */
    clientID: Ember.computed.readOnly('config.clientID'),

    /**
     * The Auth0 App Domain found in your Auth0 dashboard
     * @type {String}
     */
    domain: Ember.computed.readOnly('config.domain'),

    /**
     * The URL to return to when logging out
     * @type {String}
     */
    logoutReturnToURL: Ember.computed('config.{logoutReturnToURL,logoutReturnToPath}', function () {
      var logoutReturnToPath = Ember.get(this, 'config.logoutReturnToPath');

      if (logoutReturnToPath) {
        (false && !(logoutReturnToPath.startsWith('/')) && Ember.assert('ember-simple-auth-auth0 logoutReturnToPath must start with /', logoutReturnToPath.startsWith('/')));
        return window.location.origin + logoutReturnToPath;
      }

      return Ember.get(this, 'config.logoutReturnToURL');
    }),

    /**
     * Enable user impersonation. This is opt-in due to security risks.
     * @type {bool}
     */
    enableImpersonation: Ember.computed.bool('config.enableImpersonation'),

    /**
     * Number of seconds between auto-renewing token via silent authentication.
     * @type {number}
     */
    silentAuthRenewSeconds: Ember.computed.readOnly('config.silentAuth.renewSeconds'),

    /**
     * Automatically perform silent authentication on session restore.
     * @type {bool}
     */
    silentAuthOnSessionRestore: Ember.computed.bool('config.silentAuth.onSessionRestore'),

    /**
     * Automatically perform silent authentication on session expiration.
     * @type {bool}
     */
    silentAuthOnSessionExpire: Ember.computed.bool('config.silentAuth.onSessionExpire'),

    /**
     * Default options to use when performing silent authentication.
     * This is a function rather than a computed property since the
     * default redirectUri needs to be regenerated every time.
     * 
     * @method _getSilentAuthOptions
     * @return {Object}
     */
    _getSilentAuthOptions: function _getSilentAuthOptions() {
      var defaultOptions = {
        responseType: 'token',
        scope: 'openid',
        redirectUri: window.location.origin,
        timeout: 5000
      };
      var configOptions = Ember.getWithDefault(this, 'config.silentAuth.options', {});
      var redirectPath = configOptions.redirectPath; // Support redirectPath which becomes redirectUri with the origin location prepended.

      if (redirectPath) {
        (false && !(redirectPath.startsWith('/')) && Ember.assert('ember-simple-auth-auth0 redirectPath must start with /', redirectPath.startsWith('/')));
        configOptions.redirectUri = window.location.origin + redirectPath;
      } // [XA] convoluted assign logic, just in case the Ember.Merge fallback is used.


      var options = {};
      Ember.assign(options, defaultOptions);
      Ember.assign(options, configOptions);
      return options;
    },

    /**
     * Perform Silent Authentication with Auth0's checkSession() method.
     * Returns the authenticated data if successful, or rejects if not.
     *
     * This method does NOT actually create an ember-simple-auth session;
     * use the authenticator rather than calling this directly.
     *
     * @method silentAuth
     * @param {Object} options
     */
    silentAuth: function silentAuth(options) {
      var _this = this;

      if (!options) {
        options = this._getSilentAuthOptions();
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var auth0 = _this._getAuth0Instance();

        auth0.checkSession(options, function (err, data) {
          if (!err) {
            // special check: running this with Ember Inspector active
            // results in an ember version object getting returned for
            // some oddball reason. Reject and warn the user (dev?).
            if (data && Ember.get(data, 'type') === 'emberVersion') {
              reject(new _errors.Auth0Error('Silent Authentication is not supported when Ember Inspector is enabled. Please disable the extension to re-enable support.'));
            } else {
              resolve(data);
            }
          } else {
            reject(new _errors.Auth0Error(err));
          }
        });
      });
    },

    /**
     * Creates an authorization header from the session's token and calls
     * the given function, passing the header name & value as parameters.
     *
     * This method exists mainly for convencience, though it serves as a
     * handy drop-in replacement for the now-deprecated jwtAuthorizer.
     *
     * Just like with ember-simple-auth's authorizers, this method will do
     * nothing if the session is not authenticated.
     *
     * @method authorize
     * @param {Object} block
     */
    authorize: function authorize(block) {
      if (Ember.get(this, 'session.isAuthenticated')) {
        var userToken = Ember.get(this, 'session.data.authenticated.idToken');

        if (Ember.isPresent(userToken)) {
          block('Authorization', "Bearer ".concat(userToken));
        } else {
          Ember.debug('Could not find idToken in authenticated session data.');
        }
      }
    },

    /**
     * Redirect to Auth0's Universal Login page.
     *
     * @method universalLogin
     * @param {Object} options
     */
    universalLogin: function universalLogin(options) {
      // save the attempted transition URL so ember-simple-auth
      // will restore it once Auth0 redirects back to the app.
      var transitionPath = Ember.get(this, 'session.attemptedTransition.intent.url');

      if (transitionPath) {
        Ember.get(this, 'cookies').write('ember_simple_auth-redirectTarget', transitionPath, {
          path: '/',
          secure: window.location.protocol === 'https:'
        });
      } // redirect to the login page.


      var auth0 = this._getAuth0Instance();

      var authOptions = Ember.assign({
        redirectUri: window.location.origin
      }, options);
      auth0.authorize(authOptions); // since the above triggers a redirect away from the
      // Ember app, return a never-fulfilling promise.

      var noop = function noop() {};

      return new Ember.RSVP.Promise(noop);
    },

    /**
     * Show Lock.
     *
     * @method showLock
     * @param {Object} options
     * @param {String} clientID
     * @param {String} domain
     * @param {Boolean} passwordless
     */
    showLock: function showLock(options) {
      var _this2 = this;

      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var passwordless = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._getAuth0LockInstance(options, clientID, domain, passwordless).then(function (lock) {
          _this2._setupLock(lock, resolve, reject);

          lock.show();
        }, reject);
      });
    },
    showPasswordlessLock: function showPasswordlessLock(options) {
      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      return this.showLock(options, clientID, domain, true);
    },
    _setupLock: function _setupLock(lock, resolve, reject) {
      lock.on('authenticated', function (authenticatedData) {
        if (Ember.isEmpty(authenticatedData)) {
          return reject(new _errors.Auth0Error('The authenticated data did not come back from the request'));
        }

        lock.getUserInfo(authenticatedData.accessToken, function (error, profile) {
          if (error) {
            return reject(new _errors.Auth0Error(error));
          }

          return resolve((0, _createSessionDataObject.default)(profile, authenticatedData));
        });
      }); // [XA] shim for tests -- need to wait until the above 'authenticated'
      // listener is registered before triggering it during unit tests.

      if (this.get('inTesting')) {
        lock.trigger('_setupCompleted');
      }
    },
    _getAuth0LockModule: function _getAuth0LockModule() {
      return emberAutoImportDynamic("auth0-lock");
    },
    _getAuth0LockInstance: function _getAuth0LockInstance(options) {
      var clientID = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var domain = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var passwordless = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      clientID = clientID || Ember.get(this, 'clientID');
      domain = domain || Ember.get(this, 'domain');
      return this._getAuth0LockModule().then(function (module) {
        var Auth0LockConstructor = passwordless ? module.Auth0LockPasswordless : module.Auth0Lock;
        return new Auth0LockConstructor(clientID, domain, options);
      });
    },
    _getAuth0Instance: function _getAuth0Instance() {
      var clientID = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var domain = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      clientID = clientID || Ember.get(this, 'clientID');
      domain = domain || Ember.get(this, 'domain');
      return new _auth0Js.default.WebAuth({
        domain: domain,
        clientID: clientID
      });
    },
    _navigateToLogoutURL: function _navigateToLogoutURL(logoutUrl) {
      var _EmberGetProperties = Ember.getProperties(this, 'domain', 'logoutReturnToURL', 'clientID'),
          domain = _EmberGetProperties.domain,
          logoutReturnToURL = _EmberGetProperties.logoutReturnToURL,
          clientID = _EmberGetProperties.clientID;

      logoutReturnToURL = logoutUrl || logoutReturnToURL;

      if (!this.get('inTesting')) {
        window.location.replace("https://".concat(domain, "/v2/logout?returnTo=").concat(logoutReturnToURL, "&client_id=").concat(clientID));
      }
    },
    logout: function logout(logoutUrl) {
      var _this3 = this;

      Ember.get(this, 'session').invalidate().then(function () {
        _this3._navigateToLogoutURL(logoutUrl);
      });
    },
    _environmentConfig: Ember.computed({
      get: function get() {
        return Ember.getOwner(this).resolveRegistration('config:environment');
      }
    })
  });

  _exports.default = _default;
});